<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                v-if="$nova.hasRight(right, 'add')"
                @click="
                  addAreadialog = true;
                  areaObject = {};
                  error = {};
                "
                elevation="0"
                small
                ><v-icon>mdi-plus</v-icon>Add</v-btn
              >
              <v-btn icon small class="ml-1" @click="refreshData()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <lb-string
                class="ml-1"
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
            </div>
          </v-card-text>
          <v-card-text>
            <DataTable
              :headers="headers"
              :items="formatedData"
              :enableslot="['action']"
              :loading="loading"
            >
              <template v-slot:action="{ item }">
                <v-tooltip
                  bottom
                  content-class="tooltip-bottom"
                  v-if="$nova.hasRight(right, 'edit')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="areaEdit(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Area</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  content-class="tooltip-bottom"
                  v-if="item.status && $nova.hasRight(right, 'enable/disable')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      @click="statusDis(item._id)"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Disable</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  content-class="tooltip-bottom"
                  v-else-if="$nova.hasRight(right, 'enable/disable')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      @click="statusEnb(item._id)"
                    >
                      <v-icon>mdi-check-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Enable</span>
                </v-tooltip>
              </template>
            </DataTable>
            <!-- <v-simple-table class="pbc-view-table" dense>
              <thead>
                <tr>
                  <th scope="#">#</th>
                  <th scope="Name">Name</th>
                  <th scope="columns">Parents</th>
                  <th scope="columns">Alias</th>

                  <th scope="Action">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(v, k) in searchResult">
                  <tr :key="k">
                    <td
                      :class="`${
                        v.status
                          ? 'border-left-transparent'
                          : 'border-left-error'
                      }`"
                    >
                      <span>{{ k + 1 }}</span>
                    </td>
                    <td>
                      <span
                        v-html="$nova.getHighlitedText(v.name || '', seachList)"
                      >
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ (parentList[v.group] || {}).name || "Primary" }}
                      </span>
                    </td>
                    <td>
                      {{ v.alias }}
                    </td>

                    <td> -->
            <!-- <v-menu offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="view-on-hover-item-d mx-0 px-0"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            ><v-icon>mdi-menu</v-icon></v-btn
                          >
                        </template>
                        <v-list dense class="pa-0 border-left-default">
                          <v-list-item @click="areaEdit(v)">
                            <v-list-item-title
                              ><v-icon left>mdi-pencil</v-icon
                              >Edit</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            v-if="v.status"
                            @click="statusDis(v._id)"
                          >
                            <v-list-item-title
                              ><v-icon color="error" left>mdi-cancel</v-icon>In
                              Active</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item v-else @click="statusEnb(v._id)">
                            <v-list-item-title
                              ><v-icon color="success" left
                                >mdi-check-bold </v-icon
                              >Active</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu> -->
            <!-- <v-tooltip
                        bottom
                        content-class="tooltip-bottom"
                        v-if="$nova.hasRight(right, 'edit')"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="areaEdit(v)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Area</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                        content-class="tooltip-bottom"
                        v-if="
                          v.status && $nova.hasRight(right, 'enable/disable')
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="statusDis(v._id)"
                          >
                            <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Disable</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                        content-class="tooltip-bottom"
                        v-else-if="$nova.hasRight(right, 'enable/disable')"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            @click="statusEnb(v._id)"
                          >
                            <v-icon>mdi-check-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Enable</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table> -->
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="addAreadialog" max-width="600" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="areaObject._id"
            >Update Area</span
          >
          <span class="subtitle-1 white--text" v-else>Create New Area</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addAreadialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                label="Name"
                v-model="areaObject.name"
                :error="errors['name']"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string label="Name Alias " v-model="areaObject.alias" />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                class="flex-grow-1"
                label="Parent"
                v-model="areaObject.group"
                :items="groupList"
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            v-if="areaObject._id"
            @click="areaUpdate()"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="areaCreate()"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  data() {
    return {
      right: "conserve_area",
      loading: false,
      errors: {},
      areaObject: {},
      seachList: "",
      name: null,
      parent: "",
      alias: null,
      parentList: { _id: "", group: "Primary" },
      addAreadialog: false,
      groupList: [],
      editId: null,
      totalParent: [],
      areaList: [],
      formatedData: [],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "parent",
          text: "Parent",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "alias",
          text: "Alias",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
    };
  },
  components: {
    DataTable,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  computed: {
    searchResult() {
      let tempList = [...this.areaList];
      let active = true;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          return item.name.toUpperCase().includes(this.seachList.toUpperCase());
        });
      } else active = false;

      for (const i of tempList) {
        i.active = active;
      }
      return tempList;
    },
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.areaList.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          name: item.name,
          alias: item.alias,
          group: item.group,
          parent: this.parentList[item.group]?.name || "Primary",
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/area/list")
        .then((dt) => {
          this.groupList = dt.data.data;
          this.groupList.unshift({ _id: "", name: "Primary" });
          return this.axios.post("/v2/conserve/area/get");
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.areaList = dt.data.data;

            let parentIdList = {};
            for (let i = 0; i < this.areaList.length; i++) {
              if (this.areaList[i].group == "") {
                parentIdList[this.areaList[i]._id] = this.areaList[i];
              }
            }
            this.parentList = parentIdList;
            this.formateData();
          } else throw new Error(dt.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    areaEdit(item) {
      this.areaObject = { ...item };
      this.addAreadialog = true;
      this.errors = {};
    },
    areaCreate() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/area/add", {
          data: this.areaObject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "add successfully");
            this.addAreadialog = false;
            this.areaObject = {};
            this.$store.commit("sbSuccess", "added successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Adding Area");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    areaUpdate() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/area/edit/" + this.areaObject._id, {
          data: this.areaObject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "update successfully");
            this.addAreadialog = false;
            this.areaObject = {};
            this.$store.commit("sbSuccess", "Update successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Updateing Area");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    statusDis(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/area/disable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "disable successfully");
            this.refreshData();
          } else throw new Error(dt.data.message || "Error disable Area");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    statusEnb(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/area/enable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "enable successfully");
            this.refreshData();
          } else throw new Error(dt.data.message || "Error Enable Area");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.v-list-group.v-list-group--disabled.v-list-group--no-action {
  border-bottom: 2px solid #ef9a9a;
}
</style>